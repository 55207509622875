import React from "react"

export function getCurrentTicks() {
    const url = `rest/ticks`;
    return fetch(url, {
        headers: {'Content-Type': 'application/json; charset=UTF-8'},
        method: 'get'
    }).then(response => {
        // If the HTTP response is 2xx then it response.ok will have a value of true
        if (response.ok) {
            return response.text()
        } else {
            // If the API responds meaningful error message,
            // then you can get it by calling response.statusText
            throw new Error("Sorry something went wrong")
        }
    })
}

export function getLastCurrentTicks() {
    const url = `rest/ticksLastOnly`;
    return fetch(url, {
        headers: {'Content-Type': 'application/json; charset=UTF-8'},
        method: 'get'
    }).then(response => {
        // If the HTTP response is 2xx then it response.ok will have a value of true
        if (response.ok) {
            return response.text()
        } else {
            // If the API responds meaningful error message,
            // then you can get it by calling response.statusText
            throw new Error("Sorry something went wrong")
        }
    })
}
