import React from 'react';
import markerTemplate from './MarkerTemplate.js';
import {getCurrentTicks, getLastCurrentTicks} from "./service/fecthTicksService";

import Chart, {
    ArgumentAxis,
    CommonAxisSettings,
    CommonSeriesSettings,
    Crosshair,
    Font,
    Grid,
    Label,
    Legend,
    Point,
    Series
} from 'devextreme-react/chart';

import RangeSelector, {Behavior, Chart as ChartOptions, Margin, Scale, Size} from 'devextreme-react/range-selector';

class App extends React.Component {


    onLegendClick(e) {
        e.target.isVisible() ? e.target.hide() : e.target.show();
    }

    constructor(props) {
        super(props);
        let infoText = '5 minute ticks';
        const startValue = 1;
        this.state = {
            zoomingData: [],
            range: [],
            max_value: 0,
            visualRange: {startValue: startValue},
        };

        // TODO - if client is NOT mobile
        if (window.innerWidth > 760) {
            getCurrentTicks().then(data => {
                let arrayData = JSON.parse(data);
                const max_value = arrayData[arrayData.length - 1].id;
                let range = [1, max_value];
                if (max_value > 1000) {
                    range = [max_value - 500, max_value];
                }
                this.setState({
                    zoomingData: arrayData,
                    range: range,
                    max_value: max_value,
                    visualRange: {startValue: 1},
                });
            });
        } else {
            infoText = '5 minute ticks (only the last ticks)';
            getLastCurrentTicks().then(data => {
                let arrayData = JSON.parse(data);
                const max_value = arrayData[arrayData.length - 1].id;
                let range = [1, max_value];
                if (max_value > 1000) {
                    range = [max_value - 500, max_value];
                }
                this.setState({
                    zoomingData: arrayData,
                    range: range,
                    max_value: max_value,
                    visualRange: {startValue: 1},
                    infoText: infoText
                });
            });
        }
        this.updateVisualRange = this.updateVisualRange.bind(this);
    }

    updateVisualRange(e) {
        this.setState({visualRange: e.value});
    }

    render() {
        const max_value = this.state.max_value;
        let range = [1, max_value];
        if (max_value > 1000) {
            range = [max_value - 500, max_value];
        }
        const visualRannge = this.state.visualRange;
        const zoomingData = this.state.zoomingData;
        const theRange = this.state.range;
        const maxValue = this.state.max_value;
        return (
            <React.Fragment>

                <Chart name="zoomedChart"
                       id="zoomedChart"
                       palette="Harmony Light"
                       dataSource={zoomingData}
                       onLegendClick={this.onLegendClick}
                >

                    <CommonAxisSettings>
                        <Grid visible={true}/>
                    </CommonAxisSettings>

                    <Size
                        height={1100}
                    />
                    <Series argumentField="id" valueField="price" name="Price"/>
                    <Series argumentField="id" valueField="break_even" name="Break Even Price"/>
                    <Series argumentField="id" valueField="total_res" name="Total result"/>
                    <Series argumentField="id" valueField="sell_fee" name="Sell fee"/>
                    <Series argumentField="id" valueField="total_res_inc_sell_fee" name="Total including sell Fee"/>
                    <ArgumentAxis visualRange={visualRannge}/>

                    <Legend visible={true}
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            markerRender={markerTemplate}
                    />
                    <CommonSeriesSettings>
                        <Point size={1}/>
                    </CommonSeriesSettings>

                    <CommonSeriesSettings
                        argumentField="id">
                        <Point visible={false}></Point>
                    </CommonSeriesSettings>

                    <Crosshair
                        enabled={true}
                        color="#949494"
                        width={2}
                        dashStyle="dot"
                    >
                        <Label
                            visible={true}
                            backgroundColor="#949494"
                        >
                            <Font
                                color="#fff"
                                size={12}
                            />
                        </Label>
                    </Crosshair>
                </Chart>
                <RangeSelector
                    dataSource={zoomingData}
                    dataSourceField={"id"}
                    onValueChanged={this.updateVisualRange}
                    allowDecimals={false}
                    title={this.state.infoText}
                    defaultValue={theRange}
                >
                    <Size
                        height={120}
                    />

                    <Margin left={1}/>
                    <Scale
                        startValue={2}
                        endValue={maxValue}
                        valueType={'numeric'}
                        allowDecimals={false}
                        minorTickInterval={1}
                        tickInterval={1}/>

                    <ChartOptions palette="Harmony Light">
                        <Behavior callValueChanged="onMovingComplete"/>
                        <Legend visible={true}/>
                    </ChartOptions>
                    <Behavior snapToTicks={true}/>
                </RangeSelector>
            </React.Fragment>
        );
    }
}

export default App;